.container-onepage {
    [class*="parallax__"] {

        background-attachment: fixed;
        background-position: center center;
        background-size: cover;
        position: relative;
    }


    [class*="aside__"] {
        padding: 30px 50px;

        img {
            margin-top: 32px;
        }
        h3 {
            font-size: 60px;
            font-size: 3.750em;
            line-height: 59px;
            color: $sabiana-blue;
        }

        h4 {
            font-size: 28px;
            font-size: 1.750em;
            font-weight: 700;
        }
    }
}
.parallax__about-us {
    background-image: url('/img/company/about_us_bg.jpg');
    padding: 10%;
}

.parallax__comfort {
    background-image: url('/img/company/comfort_bg.jpg');
    padding: 10%;
}

.parallax__efficiency {
    background-image: url('/img/company/efficiency_bg.jpg');
    padding: 10%;
}

.parallax__innovation {
    background-image: url('/img/company/innovation_bg.jpg');
    padding: 10%;
}

.parallax__made-in-italy {
    background-image: url('/img/company/madeInItaly_bg.jpg');
    padding: 10%;
}

.parallax__people {
    background-image: url('/img/company/people_bg.jpg');
    padding: 10%;
}

div.container-onepage {
    padding: 0 ;
    margin: 0;
    font-family: $font-stack;



    h1 {
        //padding-top: 25%;
        margin: 0;
        font-size: 60px;
        font-size: 3.750em;
        color: $sabiana-white;
        text-shadow: 2px 2px 6px rgba(0,0,0,0.75);
        padding-bottom: 10%;
    }

    h2 {
        font-weight: 700;
        font-size: 28px;
        font-size: 1.750em;
        letter-spacing: 0.7px;
        color: $sabiana-white;
        padding: 0;
        margin: 0;
    }

    p {
        font-size: 23px;
        font-size: 1.438em;
        line-height: 30px;
        letter-spacing: 0.58px;
        margin-top: 33px;
        text-align: justify;
        padding-bottom: 85px;
        &.light {
            color: $sabiana-white;
            color: #ffffff;
            margin-top: 32px;
            text-align: center !important;
        }

        &.dark {
            color: #ffffff;
            // padding: 0 150px;
            margin-top: 32px;
            text-align: center !important;
        }
    }

    a.read-more {
        position: absolute;
        bottom: 40px;
        left: 50%;
        color: $sabiana-white;
        display: table-cell;
        background: red;
        width: 80px;
        height: 80px;
        text-align: center;
        margin-left: -40px;
        padding-top: 50px;
        background: url('/img/cross.png') 50% top no-repeat;
        cursor: pointer;

        &.collapsed {
            background: url('/img/plus.png') 50% top no-repeat;
        }
    }

    .aside__comfort ul li {
        font-size: 21px;
    }
}

nav#onepage {
    z-index: 100;
    position: fixed;
    left: 20px;
    top: 40%;
    color: $sabiana-white;

    a {
        color: $sabiana-white;
        text-shadow: 2px 2px 5px black;
        &:hover {
            color: $sabiana-text;
            text-decoration: none;
        }
        &:focus {
            outline: none;
            text-decoration: none;
        }
    }

    ul {
        margin-right: 6px;
        list-style: none;

        li {
            padding: 5px 0;
            letter-spacing: 0.4px;
            font-size: 21px;


        }
    }
}

/* Extra Small Devices, Phones 480px */
@media only screen and (min-width : $screen-xs-min) {

  div.container-onepage {

    .container-onepage {
        p {
            padding-bottom: 80px;
        }
    }

  }

}
/* Small Devices, Tablets 768px */
@media only screen and (min-width : $screen-sm-min) {

  .container-onepage {
      p {
          padding-bottom: 50px;
      }
  }

}

/* Medium Devices, Desktops 992px */
@media only screen and (min-width : $screen-md-min) {

  .container-onepage {
      section {
        padding-left: 200px;
      }
  }

}

/* Large Devices, Wide Screens 1200px */
@media only screen and (min-width : $screen-lg-min) {

}

/* Extra Large Devices, Ultra Wide Screens 1560px */
@media only screen and (min-width : $screen-xl-min) {

}
