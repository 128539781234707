/**
 * Carousel
 */
#carousel-home {
    .carousel-caption {
        position: absolute;
        top: 17%;
        bottom: 17%;
        background: rgba(0,0,0,0.40);
        left: 20%;
        right: 20%;
        display: flex;
        justify-content: center;
        align-items: center;
        h3 {
            font-size: 1.5rem;
            margin-top: 5px;
        }
        p {
            font-size: 1rem;
            margin-bottom: 0px;
        }
        .read-more {
          a {
              color: #FFFFFF;
              font-size: 0.8rem;
              margin-bottom: 0px;
          }
          i {
            top: 0px;
          }
        }
    }
    img {
        min-width: 100%;
    }

    .carousel-control {
        .icon-arrow-left,
        .icon-arrow-right {
            font-size: 2em;
            position: absolute;
            top: 50%;
            margin-top: -36px;
            display: inline-block;
        }
        .icon-arrow-left {
            left: 50%;
            margin-left: -10px;
        }
        .icon-arrow-right {
            right: 50%;
            margin-right: -10px;
        }
    }
}

 /* Extra Small Devices, Phones 480px */
 @media only screen and (min-width : $screen-xs-min) {
    #carousel-home {
        .carousel-caption {
            //background: rgba(255,0,0,0.40); // DEBUG
            h3 {
                font-size: 2.125rem;
            }
            p {
                font-size: 1.125rem;
                margin: 0px !important;
            }
            .read-more {
              a {color: #FFFFFF; font-size: 0.80rem;}
              i {margin-top: 0px;}
            }
        }

        .carousel-control {
            .icon-arrow-left,
            .icon-arrow-right {
                font-size: 2rem;
            }
        }
    }
 }
 /* Small Devices, Tablets 768px */
@media only screen and (min-width : $screen-sm-min) {
    #carousel-home {

        .carousel-caption {
            top: 25%;
            bottom: 25%;
            //background: rgba(0,255,0,0.40); // DEBUG
            h3 {
                font-size: 2.75rem;
            }
            p {
                font-size: 1.25rem;
            }
            .read-more {
              a {color: #FFFFFF; font-size: 1rem;}
            }
        }
        .carousel-control {
            .icon-arrow-left,
            .icon-arrow-right {
                font-size: 3rem;
            }
        }
    }
}

 /* Medium Devices, Desktops 992px */
@media only screen and (min-width : $screen-md-min) {
    #carousel-home {

        .carousel-caption {
            top: 25%;
            bottom: 25%;
            //background: rgba(0,0,255,0.40); // DEBUG
            h3 {
                font-size: 3.375rem;
            }
            p {
                font-size: 1.625rem;
            }
            .read-more {
              a {color: #FFFFFF; font-size: 1.5rem;}
            }
        }
        .carousel-control {
            .icon-arrow-left,
            .icon-arrow-right {
                font-size: 3.5rem;
            }
        }
    }
}

 /* Large Devices, Wide Screens 1200px */
@media only screen and (min-width : $screen-lg-min) {
    #carousel-home {

        .carousel-inner {
            .item {
                .carousel-caption {
                    top: 27%;
                    bottom: 27%;
                    //background: rgba(255,255,0,0.40); // DEBUG
                    h3 {
                        font-size: 4.625rem;
                    }
                    p {
                        font-size: 2.25rem;
                    }
                    .read-more {
                      a {color: #FFFFFF; font-size: 1.5rem;}
                    }
                }
            }
        }
        .carousel-control {
            .icon-arrow-left,
            .icon-arrow-right {
                font-size: 4rem;
            }
        }
    }
}

 /* Extra Large Devices, Ultra Wide Screens 1560px */
@media only screen and (min-width : $screen-xl-min) {
    #carousel-home {

        .carousel-inner {
            .item {
                .carousel-caption {
                    top: 30%;
                    bottom: 30%;
                    //background: rgba(255,0,255,0.40); // DEBUG
                    h3 {
                        font-size: 5.875rem;
                    }
                    p {
                        font-size: 2.875rem;
                    }
                    .read-more {
                      a {color: #FFFFFF; font-size: 2rem;}

                    }
                }
            }
        }
        .carousel-control {
            .icon-arrow-left,
            .icon-arrow-right {
                font-size: 5rem;
            }
        }
    }
}

/**
 * Indoor Air Quality
 */
.jumbotron.indoor-air-quality {

	margin-top: 30px;
	padding-top: 50px;
	height: 100vh;
	background: url('/img/indoor-air-quality/indoor-air-quality_bg.jpg') bottom center;
	background-size: cover;
  min-height: 620px;
   	background-repeat: no-repeat;
	h3 {
        margin-top: 20%;
		font-size: 2em;
		color: $sabiana-white;
	}
	img {
		width: 100%;
	}
	p {
		font-size: 1.5em;
		color: $sabiana-white;
	}
	a {
		color: $sabiana-white;
        font-size: 16px;
	}
	margin-bottom: 0;
}

/* Extra Small Devices, Phones 480px */
@media only screen and (min-width : $screen-xs-min) {
   .jumbotron.indoor-air-quality {
     min-height: 600px;
       h3 {
         font-size: 2em;
       }
       p {
         font-size: 1.5em;
       }
   }
}
 /* Small Devices, Tablets 768px */
@media only screen and (min-width : $screen-sm-min) {
   .jumbotron.indoor-air-quality {
     min-height: 690px;
       h3 {
         font-size: 2.5em;
       }
       p {
         font-size: 1.75em;
       }
   }
}
/* Medium Devices,  992px */
@media only screen and (min-width : $screen-md-min) {
   .jumbotron.indoor-air-quality {
     min-height: 800px;
       h3 {
         font-size: 3em;
       }
       p {
         font-size: 2em;
       }
   }
}
/* large Devices,  1200px */
@media only screen and (min-width : $screen-lg-min) {
   .jumbotron.indoor-air-quality {
     min-height: 850px;
       h3 {
         font-size: 3em;
       }
       p {
         font-size: 2em;
       }
   }
}

/* Extra Large Devices,  1560px */
@media only screen and (min-width : $screen-xl-min) {
   .jumbotron.indoor-air-quality {
     min-height: 970px;
       h3 {
         font-size: 3em;
       }
       p {
         font-size: 2em;
       }
   }
}

/**
 * Product Category
 */
 .home-category-list {
    display: flex;
    flex-wrap: wrap;
 }
 .prodcategory {
   flex-grow: 1;
   transition: all .2s ease-in-out;
   text-align: center;
   background: $sabiana-light-grey;
   color: $sabiana-text;
   border: 2px solid #fff;
   min-height: 200px;
   min-width: 170px;
   padding: 20px 0;
   .icon-unit-heaters { color: $sabiana-unit-heaters; }
   .icon-fan-coils { color: $sabiana-fan-coils; }
   .icon-filters { color: $sabiana-filters; }
   .icon-stainless-steel-flues { color: $sabiana-stainless-steel-flues; }
   .icon-other-products { color: $sabiana-other-products; }
   .icon-radiant-panels { color: $sabiana-radiant-panels; }
   .icon-recovery-units { color: $sabiana-recovery-units; }
   .icon-air-handling-units { color: $sabiana-air-handling-units; }
   .icon-fan-convector { color: $sabiana-air-handling-units; }

   i {
     text-align: left;
   }
   h5 {
     margin-top: 50px;
   }
   a {
     color: $sabiana-dark-grey;
     font-weight: 400;
     &:hover {
       text-decoration: none;
     }
   }
   &:hover {
     transform: scale(1.01);
     z-index: 100;
     -webkit-box-shadow: 0px 0px 40px 0px rgba(0,0,0,0.75);
     -moz-box-shadow: 0px 0px 40px 0px rgba(0,0,0,0.75);
     box-shadow: 0px 0px 40px 0px rgba(0,0,0,0.75);
   }
 }

.img_bg {
    width: 42%;
    height: 200px;
    float: left;
    background-size: cover;
    background-position: center;
  }
.img_bg_full {
    width: 100%;
    height: 220px;
    float: left;
    background-size: cover;
    background-position: center;
  }
  
.box_news {
	background: $sabiana-light-grey;
}

.loop_news {
	background: $sabiana-light-grey;

}

.partners {
	background: $sabiana-white;
	margin: 60px auto;
	h2 {
		font-size: 48px;
		margin-bottom: 20px;
	}
}

.downloads {
	background: $sabiana-white;
	margin: 60px auto;
	.dark {
		a {
			color: $sabiana-white;
			&:hover, &:active {
				color: $sabiana-mid-grey;
				text-decoration: none;
			}
		}
		h2 {
			font-size: 48px;
			line-height: 170px;
		}
		h3 {
			margin: 0;
			padding: 20px 0;
			@include bold();
		}
		p {
			font-size: 20px;
		}
		ul {
			margin-top: 25px;
		}
		ul li {
			list-style-type: none;
		}
		color: $sabiana-white;
		min-height: 260px;
		background: $sabiana-dark-grey;
	}
}

.forms {
	margin-bottom: 30px;
	padding: 20px;
	background: $sabiana-white;
	h2 {
		margin-top: -8px;
	}
}

@media (max-width: 768px) {
	.loop_news {
		.panel {
			.panel-heading {
				min-height: 120px;
			}
		}
	}
}

.home-press {
    article {
        margin: 0 0 14px 10px;
        background: #f7f7f7;;
    }
}