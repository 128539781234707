
// Gallery
@media screen and (min-width: 990px) {
  #carousel-event{
      img {
          min-height: 600px;
          height: 600px;
          max-height: 600px;
          margin: 0 auto;
          min-width: auto;
      }
    .carousel-control {
        &.right {
            background: transparent;
        }
        &.left {
            background: transparent;
        }
      }
    .carousel-control .icon-arrow-left {
      color: $sabiana-blue;
        margin-left: -200px;
    }

    .carousel-control .icon-arrow-right {
      color: $sabiana-blue;
        margin-right: -200px;
    }

    .carousel-indicators {
        bottom: -70px;
    }

    .carousel-indicators li {
        border: 1px solid $sabiana-blue;
    }
    .carousel-indicators li.active {
        background-color: $sabiana-blue;
    }
  }
}
