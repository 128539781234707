.top-menu {
    ul.sidebar-menu {
        //background: red;
        list-style: none;
        padding: 30px 0;
        margin: 0 10px;
        //width: 50%;
        float: right;
        overflow: scroll;
        li {
            list-style: none;
        }
        > li {
            list-style: none;
            //background: green;
            border-bottom: 1px solid black;
        }
    }
}

@media (max-width: $screen-xs-max) {
    .top-menu__right {
        position: absolute;
        max-width: 70%;
        right: 0;
    }
}
.dropdown-menu i {
    display: inline-block;
}
/*

    .navbar-header ul.nav li a {
        @include light();
    }

    .navbar-brand img {
        margin-left: 15px;
    }

    .navbar-right {
        @include bold();
        font-size: 13px;
        font-size: 0.813em;
        letter-spacing: 0.33px;
        color: $sabiana-mid-grey;
        text-transform: uppercase;
    }
}

.nav:after {
    content: '';
    display: inline;
}

.container-fluid.top-menu {
    margin-top: -96px;
    padding-top: 12px;
    padding-bottom: 12px;
    background: $sabiana-light-blue;
}

.top-menu {
    img {
        margin-left: 15px;
    }

    ul.nav {
        margin-top: 36px;
        margin-left: 30px;

        li {
            a {
                padding: 0;
                color: $sabiana-dark-grey;
                font-size: 23px;
                font-size: 1.438em;
                letter-spacing: 0.58px;
                white-space: nowrap;
                background: none;

                &:hover {
                    color: $sabiana-blue;
                }
            }

            ul li a {
                font-size: 18px;
            }

            &.active a {
                color: $sabiana-blue;
            }
            &.active ul li a {
                color: $sabiana-dark-grey !important;
            }
        }
    }
}
@media (max-width: 425px) {
    .navbar-nav > li {
        //margin-left: -15px;
    }
}

.dropdown-menu {
    border: 0;
    border-radius: 0;
    font-size: 18px;
    font-size: 1.125em;

    li {
        margin: 15px 25px 15px 5px;
        padding-left: 10px;

        a {
            @include light();
        }
    }
}

.top-menu {
    position: fixed;
    width: 100%;
    z-index: 1000;

    .nav-justified > .dropdown .dropdown-menu {
        top: 46px;
        left: 35px;
    }

    .active {}
}
@media (min-width: 480px) {
    .top-menu {
        .navbar-collapse {
            width: auto;
            border-top: 0;
            -webkit-box-shadow: none;
            box-shadow: none;
        }
    }
}

@media (max-width: 767px) {
    .navbar-nav .open .dropdown-menu {
        position: static;
        float: none;
        width: 100px;
        margin-top: 0;
        border: 0;
        box-shadow: none;
    }
}
*/
