// Variables
@import "variables";

// Bootstrap
@import "node_modules/bootstrap-sass/assets/stylesheets/bootstrap";

// Large Layout
@import "xl"; // Extra Large Layout

// Fonts
@import "typekit"; // Myriad Typekit
@import "fontello"; // Fontello SVG
@import "font-awesome/font-awesome"; // Font Awesome

// Utilities
//@import "responsive-pull";
//@import "prevent-collapse";
@import "panel";

// Header
//@import "header";
//@import "menu";
//@import "sidebar";

// Mixins
@import "mixin";


// Sections
@import "home";
@import "carousel";
@import "indoor-air-quality";
@import "products";
@import "history";
@import "sections";
@import "newsevents";
@import "news";
@import "press";
@import "company";
@import "references";
@import "salesandsupport";
@import "downloads";
@import "profile";


// Footer
@import "footer";

// Header
@import "header";
@import "menu";



// Test Classes
.test {
    &--red {
        background: red;
    }
    &--blue {
        background: blue;
    }
    &--green {
        background: green;
    }
}

// Main Styles
html {
    font-size: 16px;
}
html,
body {
    width: 100%;
    height: 100%;
}

body {
  background: $sabiana-white;
  padding-top: 146px;
  //padding-bottom: 20px;
  @include light();
  color : $sabiana-text;
}

// Old Sabiana Styles to Check
/* Paragraphs */
p.read-more {
   a {
       color: $sabiana-mid-grey;
       &:hover {
           color: $sabiana-mid-grey;
           text-decoration: none;
       }
   }
   i {
      position: relative;
      top: 5px;
  }
}
div.truncate {
    min-height: 80px;
    display: -webkit-box;
    -webkit-line-clamp: 3;
    -webkit-box-orient: vertical;
    max-width: 100%;
    overflow: hidden;
    text-overflow: ellipsis;
    height:56px /* fallback */
 }

 p.truncate {
    min-height: 92px;
    display: -webkit-box;
    -webkit-line-clamp: 3;
    -webkit-box-orient: vertical;
    max-width: 100%;
    overflow: hidden;
    text-overflow: ellipsis;
    height:56px /* fallback */
 }

 /* Temporary Text */
 .lorem-ipsum {
     color: red;
     background: yellow;
 }

/* No background active nav link */
/*
.nav > li > a:hover, .nav > li > a:focus {
    text-decoration: none;
    background-color: transparent;
}
*/

/* Sabiana Boxes */

// .panel {
//     background: $sabiana-light-grey;
//     height: 410px;
// 	.panel-header {
//         h2 {
//             padding-left: 20px;
//         }
// 		max-height: 250px;
// 	}
// 	.panel-body {
// 		padding: 30px 20px 0;
// 		overflow: hidden;
// 	}
// 	.panel-footer {
// 		background: $sabiana-light-grey;
// 		border: 0;
// 	}
//     .tags {
//         position: absolute;
//         .icon-unit-heaters { background-color: $sabiana-unit-heaters; }
//         .icon-fan-coils { background-color: $sabiana-fan-coils; }
//         .icon-filters { background-color: $sabiana-filters; }
//         .icon-stainless-steel-flues { background-color: $sabiana-stainless-steel-flues; }
//         .icon-other-products { background-color: $sabiana-other-products; }
//         .icon-radiant-panels { background-color: $sabiana-radiant-panels; }
//         .icon-recovery-units { background-color: $sabiana-recovery-units; }
//         .icon-air-handling-units { background-color: $sabiana-air-handling-units; }
// 	  padding: 0 20px;
// 	  margin-top: -20px;
// 	  i {
// 	    color: #fff;
// 	    padding: 10px;
// 	    text-align: center;
// 	  }
// 	}
//     img {
//       width: 100%;
//     }
// }

/*
ul.nav li.dropdown ul.dropdown-menu a:focus {
    background-color: transparent !important;
}
*/
.form-group.required .control-label:after {
  content:" *";
  color: red;
}

@media only screen 
  and (min-device-width: 992px) 
  and (max-device-width: 1199px) {

}

.limit-title {
    display: block !important;
    text-overflow: ellipsis;
    word-wrap: break-word;
    overflow: hidden;
    max-height: 1.6em;
    line-height: 1.8em;
}


.mt-0 {
    margin-top: 0rem;
}

.mt-1 {
    margin-top: 1rem;
}
.mt-2 {
    margin-top: 2rem;
}


