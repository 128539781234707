.container-fluid {
  &.dark{
    background: $sabiana-dark-grey;
    color : $sabiana-white;
  }
  &.gallery {
      padding-top: 30px;
      padding-bottom: 70px;
  }
}
.headimage {
    img {
        width: 100%;
    }
}
.product {
    margin: 0;
    margin-top: 10px !important;
}


// Gallery

#carousel-product{
    img {
        margin: 0 auto;
        min-width: auto;
    }
    .carousel-control.left, .carousel-control.right {
      background: none;
    }
    .carousel-control {
        .icon-arrow-left,
        .icon-arrow-right {
            font-size: 2em;
            position: absolute;
            top: 50%;
            margin-top: -36px;
            display: inline-block;
        }
        .icon-arrow-left {
            left: 50%;
            margin-left: -10px;
        }
        .icon-arrow-right {
            right: 50%;
            margin-right: -10px;
        }
    }

  .carousel-indicators {
      bottom: -70px;
  }
}

@media screen and (min-width: 990px) {
  #carousel-product{
      img {
          min-height: 600px;
          height: 600px;
          max-height: 600px;
          margin: 0 auto;
          min-width: auto;
      }
  }
}

// End gallery
.products-categories {
  background: $sabiana-dark-grey;
  padding: 20px 0;
  color: $sabiana-light-grey;
}



//Product H1
.products-categories {
    background: #404041;
    padding: 0px 0;
    color: #f7f7f7;
}
// chevon down under products
.products-categories {
  text-align: center;
}

.canvas-nav-product {
  height: 55px;
  right: 0px;
  position: fixed;
  z-index: 1000;

  .navbar-fixed {

    position: relative;
    float: right;
    margin-right: 15px;
    padding: 9px 10px;
    margin-top: 8px;
    margin-bottom: 8px;
    background-color: transparent;
    background-image: none;
    border: 1px solid #dddddd;
    border-radius: 0;
    background-color: #FFFFFF;

    .icon-bar {
      background-color: #999999;
      display: block;
      width: 22px;
      height: 2px;
      border-radius: 1px;
      margin-top: 4px;

    }
  }
}

.products-menu {

   background: #fff;
   .nav {
     li {
       a {
         padding: 16px 15px;
        &:hover, &:focus {
          background: none;
        }
       }
     }
   }


 // due to impossibilitation to use slideToggle with hidden-xs, set display none for screen
 @media only screen and (max-width : $screen-md-min) {
   .list-product { display: none; }
   .canvas-nav-product { display: none !important; } // removing the button for every size
 }

 @media only screen and (min-width : $screen-md-min) {
   .list-product { display: inline !important; }
   .canvas-nav-product { display: none !important; }
 }


  // Closed menu
   li > a > i {
     cursor: pointer;
     &.icon-unit-heaters { color: $sabiana-unit-heaters; }
     &.icon-fan-coils { color: $sabiana-fan-coils; }
     &.icon-filters { color: $sabiana-filters; }
     &.icon-stainless-steel-flues { color: $sabiana-stainless-steel-flues; }
     &.icon-other-products { color: $sabiana-other-products; }
     &.icon-radiant-panels { color: $sabiana-radiant-panels; }
     &.icon-recovery-units { color: $sabiana-recovery-units; }
     &.icon-air-handling-units { color: $sabiana-air-handling-units; }
     &.icon-fan-convector { color: $sabiana-fan-convector; }
   }

    li > a.active {
      font-weight: 700;
    }

    li > a:hover {
      cursor: pointer;
    }
   li > a.active > i {
     margin-right: 10px;
     color: #fff;
     &.icon-unit-heaters { color: #fff; background-color: $sabiana-unit-heaters; }
     &.icon-fan-coils { color: #fff; background-color: $sabiana-fan-coils; }
     &.icon-filters { color: #fff; background-color: $sabiana-filters; }
     &.icon-stainless-steel-flues { color: #fff; background-color: $sabiana-stainless-steel-flues; }
     &.icon-other-products { color: #fff; background-color: $sabiana-other-products; }
     &.icon-radiant-panels { color: #fff; background-color: $sabiana-radiant-panels; }
     &.icon-recovery-units { color: #fff; background-color: $sabiana-recovery-units; }
     &.icon-air-handling-units { color: #fff; background-color: $sabiana-air-handling-units; }
     &.icon-fan-convector { color: #fff; background-color: $sabiana-fan-convector; }
   }

   li:hover > a > i {
     color: #fff;
     cursor: pointer;
     &.icon-unit-heaters { background-color: $sabiana-unit-heaters; }
     &.icon-fan-coils { background-color: $sabiana-fan-coils; }
     &.icon-filters { background-color: $sabiana-filters; }
     &.icon-stainless-steel-flues { background-color: $sabiana-stainless-steel-flues; }
     &.icon-other-products { background-color: $sabiana-other-products; }
     &.icon-radiant-panels { background-color: $sabiana-radiant-panels; }
     &.icon-recovery-units { background-color: $sabiana-recovery-units; }
     &.icon-air-handling-units { background-color: $sabiana-air-handling-units; }
     &.icon-fan-convector { background-color: $sabiana-fan-convector; }
   }

   li > ul > li {
     margin-left: 102px;
   }

   .icon {
     padding: 20px;
     margin-right: 10px;
   }

   color: $sabiana-text;
}

.products-menu .nav li ul {
  padding-top: -22px;
  li {
    a {
      margin: 0;
      padding: 0;
    }
    &:hover {
      font-weight: 700;
    }
  }
}

.headimage {
  max-width: 100%;
  h1 i {
      color: #fff;
      padding: 16px 15px;
      &.icon-unit-heaters { background-color: $sabiana-unit-heaters; }
      &.icon-fan-coils { background-color: $sabiana-fan-coils; }
      &.icon-filters { background-color: $sabiana-filters; }
      &.icon-stainless-steel-flues { background-color: $sabiana-stainless-steel-flues; }
      &.icon-other-products { background-color: $sabiana-other-products; }
      &.icon-radiant-panels { background-color: $sabiana-radiant-panels; }
      &.icon-recovery-units { background-color: $sabiana-recovery-units; }
      &.icon-air-handling-units { background-color: $sabiana-air-handling-units; }
      &.icon-fan-convector { background-color: $sabiana-fan-convector; }
  }
}

.headimage > img {
  width: 100%;
}

.product {
  padding-bottom: 15px;
}



/* Tabs */
#product_tabs {
    background: $sabiana-light-grey;
    text-transform: uppercase;
    margin-top: 20px;
    border: none;
    line-height : 24px;
    line-height : 1.5em;
    margin-left: 0;
    margin-right: 0;
    font-size : 15px;
    font-size : 0.938em;
    letter-spacing : 0.75px;
    color : $sabiana-dark-grey;
  li {
    display: table-cell;
     a {
      margin-right: 2px;
      line-height: 1.6;
      border: 1px solid transparent;
      border-radius: 4px 4px 0 0;
    }
  }
  .nav-tabs {
      border: 0;
  }
}

#technical-sheet img {
    width: 100% !important;
}

#photogallery slide {
  padding-bottom: 15px;
}

.fixed-panel {
  //min-height: 260px;
}

.product_miniature {
  min-height: 530px;
}

.img_products {
  img {
    width: 100%;
    max-height: 330px;
  }
  p {
    max-height: 300px;
    margin: 20px 0 0 11px;
  }
}

.download-dark {
    background: $sabiana-dark-grey;
    color: white;
    padding: 5px;
    font-weight: 100;
    font-size: 35px;
    margin-bottom: 20px;
}

.modal {
    .download_category {
        span {
            margin-top: 4px;
        }
        a {
            margin-left: 10px;
        }
    }
}
