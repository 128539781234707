.container-fluid {
  &.dark{
    background: $sabiana-dark-grey;
    color : $sabiana-white;
  }
  &.gallery {
      padding-top: 30px;
      padding-bottom: 70px;
  }
}

#carousel-product {
  .carousel-indicators {
      bottom: -50px;
  }
}
