.panel {
    background: $sabiana-light-grey;

	.panel-header {

      max-height: 250px;

        h2 {
            padding-left: 20px;
        }

	}

  .panel-heading {
      margin-bottom: -70px;
      border-radius: 0;
      time {
        background: $sabiana-blue;
        color: #fff;
        width: 65px;
        height: 65px;
        text-align: center;
        z-index: 100;
        display: inline-block;
        font-size: 20px;
        font-size: 1.250em;
        line-height: 2em;
        padding-top: 10px;
        position: absolute;
        left: 40px;
        top: 15px;
        margin-bottom: -50px;
      }
      h4 {
        display: inline;
        margin-left: 100px;
        font-size: 22px;
        font-size: 1.375em;
      }
  }

	.panel-body {
		padding: 0px 5px 0 10px;
		overflow: hidden;

    h5 {
      margin-top: 2px;
    }

    .no_img {
       margin-left: 100px;
    }

    .truncate {
       display: -webkit-box;
       -webkit-line-clamp: 3;
       -webkit-box-orient: vertical;
       max-width: 100%;
       overflow: hidden;
       text-overflow: ellipsis;
       height: 45px /* fallback */
    }

    time {
      background: $sabiana-blue;
      color: #fff;
      text-align: center;
      z-index: 100;
      display: inline-block;
      font-size: 20px;
      font-size: 1.250em;
      line-height: 3em;
      padding: 13px 5px;
      margin-right: 8px;
    }

	}

  .panel-footer {
    border: 0;
    padding: 20px 15px 0px 15px;

    .read-more {
      margin-top: 0px !important;
      position: absolute;
      bottom: -10px;
      right: 15px;
      font-size: 1em;
    }
  }
  .panel-footer:after {
        clear: both;
        content: "";
        display: table;
  }


    .tags {
        position: absolute;
        .icon-unit-heaters { background-color: $sabiana-unit-heaters; }
        .icon-fan-coils { background-color: $sabiana-fan-coils; }
        .icon-filters { background-color: $sabiana-filters; }
        .icon-stainless-steel-flues { background-color: $sabiana-stainless-steel-flues; }
        .icon-other-products { background-color: $sabiana-other-products; }
        .icon-radiant-panels { background-color: $sabiana-radiant-panels; }
        .icon-recovery-units { background-color: $sabiana-recovery-units; }
        .icon-air-handling-units { background-color: $sabiana-air-handling-units; }
	  padding: 0 20px;
	  margin-top: -20px;
	  i {
	    color: #fff;
	    padding: 10px;
	    text-align: center;
	  }
	}
    img {
      width: 44%;
      float: left;
    }
}


.panel-home {
  .panel-body {
    .truncate {
      min-height: 92px;
      display: -webkit-box;
      -webkit-line-clamp: 3;
      -webkit-box-orient: vertical;
      max-width: 98%;
      overflow: hidden;
      text-overflow: ellipsis;
      height:56px /* fallback */
    }
    .truncate::after {
      content: '...';
    }
  }
}


.panel-product {
  .panel-footer {
      margin-top: 20px;

      .read-more {
        /*bottom: -10px;*/
        right: 20px;
      }
  }
}

.panel-news {
  background: #FFFFFF;
  .panel-body {
    padding: 0px 20px 0;
    top: -6px;
  }
  .panel-footer {
    background: #FFFFFF;
    padding: 25px 15px 0 15px;
  }
}


/* Extra Small Devices, Phones */
@media only screen and (min-width : $screen-xs-min) {
  .panel {

    .panel-body {
      padding: 5px 20px 0;
      overflow: hidden;
    }

    .panel-footer {
      .read-more {
        /*bottom: -5px;*/
        right: 25px;
        position: absolute;
      }
    }

    img {
      width: 44%;
      float: left;
    }
  }

  .panel-news {
    .panel-body {
      padding: 0px 20px 0;
      overflow: hidden;
    }
  }

  .panel-product {
    .panel-footer {
        .read-more {

          right: 20px;
        }
    }
  }

}
/* Small Devices, Phones */
@media only screen and (min-width : $screen-sm-min) {
  .panel {

    .panel-heading {
      margin-bottom: 0px;
    }

      .panel-footer {
        .read-more {
          bottom: 15px;
          right: 25px;
        }
      }
  }

  .panel-product {
    .panel-footer {

        .read-more {
         /*bottom: 15px;*/
          right: 20px;
        }
    }
  }

}
/* Medium Devices, Phones */
@media only screen and (min-width : $screen-md-min) {
  .panel {

      .panel-footer {
        .read-more {
          bottom: 30px;
          right: 25px;
        }
      }
      .home-press {
        height: 122px;
        h2 {
          font-size: 1.2em;
          margin-top: 12px;
        }
      }

  }
  .panel.panel-references {
      min-height: 370px;

      img {
        width: 100%;
      }
  }
  .panel.panel-home {
      min-height: 480px;

      img {
        width: 100%;
      }

  }

  .panel-news {
      padding: 20px;
      min-height: 275px;
  }

  .panel.panel-product {
      min-height: 420px;

      img {
        width: 100%;
      }

      .panel-footer {
        .read-more {
          /*bottom: 25px;*/
          right: 20px;
        }
      }
  }

  .panel.panel-references {
      min-height: 350px;
    }

}
/* Large */
@media only screen and (min-width : $screen-lg-min) {
  .panel-news {
      .panel-footer {
        .read-more {
          bottom: 30px;
          right: 25px;
        }
      }

  }

  .panel.panel-home {
      min-height: 320px;

  }

  .panel-news {
      padding: 20px;
      /*min-height: 380px;*/
  }

  .panel.panel-references {
      min-height: 370px;

      .panel-footer {
          margin-top: 20px;
      }

  }
  .panel {
    .panel-body {
      .truncate {
        max-width: 85%;
      }
    }
  }

}

/* Extra large*/
@media only screen and (min-width : $screen-xl-min) {

  .panel.panel-references {
      min-height: 350px;

  }

  .panel.panel-product {
    .panel-footer {
        margin-top: 30px;
    }
  }
}



@media only screen 
  and (min-width: 769px) 
  and (max-width: 991px) {
    .panel-press {
      min-height: 300px;
      .home-press:first-child {
        margin-top: 12px;
      }
    }

}


