.profile {
    min-height: 45vh;
    padding-top: 100px;
    
    .btn-primary {
        margin-top: 20px;
    }
    
}


.btn-password {
    margin: 20px 0px 40px;
}

.update-success {
    font-size: 1rem;
    font-weight: bold;
    text-align: center;
    color: $sabiana-blue;
}