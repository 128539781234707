@keyframes animate-particle-1-a { // polline
    0% {
        transform:  translate(0,0)  rotate(0deg) scale(0.3, 0.3);
        opacity: 0.4;
    }
    50% {
        transform:  translate(30px,82px)  rotate(180deg) scale(0.5, 0.5);
        opacity: 1;
    }
    100% {
        transform:  translate(0,0)  rotate(0deg) scale(0.3, 0.3);
        opacity: 0.4;
    }
}
@keyframes animate-particle-1-b { // polline
    0% {
        transform:  translate(0,0)  rotate(0deg) scale(0.2, 0.2);
        opacity: 0.6;
    }
    50% {
        transform:  translate(170px,40px)  rotate(90deg) scale(0.3, 0.3);
        opacity: 1;
    }
    100% {
        transform:  translate(0,0)  rotate(0deg) scale(0.2, 0.2);
        opacity: 0.6;
    }
}
@keyframes animate-particle-1-c { // polline
    0% {
        transform:  translate(0,0)  rotate(0deg) scale(0.4, 0.4);
        opacity: 1;
    }
    50% {
        transform:  translate(80px,230px)  rotate(270deg) scale(0.2, 0.2);
        opacity: 0.7;
    }
    100% {
        transform:  translate(0,0)  rotate(0deg) scale(0.4, 0.4);
        opacity: 1;
    }
}

@keyframes animate-particle-2-a { // acaro
    0% {
      transform:  translate(0px,0px)  rotate(0deg) scale(0.2, 0.2);
      opacity: 1;
    }
    50% {
      transform:  translate(53px,157px)  rotate(22deg) scale(0.1, 0.1);
      opacity: 0.6;
    }
    100% {
      transform:  translate(0,0)  rotate(0deg) scale(0.2, 0.2);
      opacity: 1;
    }
}

@keyframes animate-particle-2-b { // acaro
    0% {
      transform:  translate(0,0)  rotate(0deg) scale(0.2, 0.2);
      opacity: 1;
    }
    50% {
      transform:  translate(177px,53px)  rotate(22deg) scale(0.1, 0.1);
      opacity: 0.6;
    }
    100% {
      transform:  translate(0,0)  rotate(0deg) scale(0.2, 0.2);
      opacity: 1;
    }
}

@keyframes animate-particle-2-c { // acaro
    0% {
      transform:  translate(0px,0px)  rotate(0deg) scale(0.2, 0.2);
      opacity: 1;
    }
    50% {
      transform:  translate(53px,157px)  rotate(22deg) scale(0.1, 0.1);
      opacity: 0.6;
    }
    100% {
      transform:  translate(0,0)  rotate(0deg) scale(0.2, 0.2);
      opacity: 1;
    }
}

@keyframes animate-particle-2-d { // acaro
    0% {
      transform:  translate(0,0)  rotate(0deg) scale(0.2, 0.2);
      opacity: 1;
    }
    50% {
      transform:  translate(177px,53px)  rotate(22deg) scale(0.1, 0.1);
      opacity: 0.6;
    }
    100% {
      transform:  translate(0,0)  rotate(0deg) scale(0.2, 0.2);
      opacity: 1;
    }
}

@keyframes animate-particle-3 { // capello diagonale
    0% {
      transform:  translate(0,0)  rotate(0deg) scale(0.5, 0.5);
    }
    25% {
      transform:  translate(0,0)  rotate(90deg) scale(0.5, 0.5);
    }
    50% {
      transform:  translate(0,0)  rotate(180deg) scale(0.5, 0.5);
    }
    75% {
      transform:  translate(0,0)  rotate(270deg) scale(0.5, 0.5);
    }
    100% {
      transform:  translate(0,0)  rotate(360deg) scale(0.5, 0.5);
    }
}

@keyframes animate-particle-4-a { // pallino sfumato
    0% {
      transform:  translate(0,0)  rotate(0deg) scale(1, 1);
      opacity: 1;
    }
    50% {
      transform:  translate(-750px,-750px)  rotate(0deg) scale(0.7, 0.7);
      opacity: 0.3;
    }
    100% {
      transform:  translate(0,0)  rotate(0deg) scale(1, 1);
      opacity: 1;
    }
}

@keyframes animate-particle-4-b { // pallino sfumato
    0% {
      transform:  translate(0,0)  rotate(0deg) scale(1, 1);
      opacity: 1;
    }
    50% {
      transform:  translate(-250px,-750px)  rotate(0deg) scale(0.4, 0.4);
      opacity: 0.6;
    }
    100% {
      transform:  translate(0,0)  rotate(0deg) scale(1, 1);
      opacity: 1;
    }
}

@keyframes animate-particle-4-c { // pallino sfumato
    0% {
      transform:  translate(0,0)  rotate(0deg) scale(1, 1);
      opacity: 1;
    }
    50% {
      transform:  translate(-550px,-150px)  rotate(0deg) scale(0.2, 0.2);
      opacity: 0.3;
    }
    100% {
      transform:  translate(0,0)  rotate(0deg) scale(1, 1);
      opacity: 1;
    }
}

@keyframes animate-particle-5 { // capello curvo
    0% {
      transform:  translate(0,0)  rotate(0deg) scale(0.6, 0.6);
    }
    25% {
      transform:  translate(0,0)  rotate(-90deg) scale(0.6, 0.6);
    }
    50% {
      transform:  translate(0,0)  rotate(-180deg) scale(0.6, 0.6);
    }
    100% {
      transform:  translate(0,0)  rotate(-360deg) scale(0.6, 0.6);
    }
}

@keyframes animate-particle-6-a { // batterio
    0% {
      transform:  translate(0,0)  rotate(0deg) scale(0.4, 0.4);
      opacity: 1;
    }
    33% {
      transform:  translate(0,0)  rotate(90deg) scale(0.3, 0.3);
      opacity: 0.8;
    }
    50% {
      transform:  translate(0,0)  rotate(180deg) scale(0.2, 0.2);
      opacity: 0.6;
    }
    67% {
      transform:  translate(0,0)  rotate(270deg) scale(0.3, 0.3);
      opacity: 0.8;
    }
    100% {
      transform:  translate(0,0)  rotate(360deg) scale(0.4, 0.4);
      opacity: 1;
    }
}

@keyframes animate-particle-6-b { // batterio
    0% {
      transform:  translate(0,0)  rotate(0deg) scale(0.3, 0.3);
      opacity: 1;
    }
    33% {
      transform:  translate(0,0)  rotate(-90deg) scale(0.2, 0.2);
      opacity: 0.6;
    }
    50% {
      transform:  translate(0,0)  rotate(-180deg) scale(0.1, 0.1);
      opacity: 0.4;
    }
    67% {
      transform:  translate(0,0)  rotate(-270deg) scale(0.2, 0.2);
      opacity: 0.6;
    }
    100% {
      transform:  translate(0,0)  rotate(-360deg) scale(0.3, 0.3);
      opacity: 1;
    }
}

@keyframes animate-particle-7-a { // pallino piccolo
    0% {
      transform:  translate(0,0)  rotate(0deg) scale(0.5, 0.5);
      opacity: 1;
    }
    50% {
      transform:  translate(-250px,-750px)  rotate(0deg) scale(0.3, 0.3);
      opacity: 0.7;
    }
    100% {
      transform:  translate(0,0)  rotate(0deg) scale(0.5, 0.5);
      opacity: 1;
    }
}

@keyframes animate-particle-7-b { // pallino piccolo
    0% {
      transform:  translate(0,0)  rotate(0deg) scale(0.5, 0.5);
      opacity: 1;
    }
    50% {
      transform:  translate(550px,-750px)  rotate(0deg) scale(0.6, 0.6);
      opacity: 0.5;
    }
    100% {
      transform:  translate(0,0)  rotate(0deg) scale(0.5, 0.5);
      opacity: 1;
    }
}

@keyframes animate-particle-7-c { // pallino piccolo
    0% {
      transform:  translate(0,0)  rotate(0deg) scale(0.5, 0.5);
      opacity: 1;
    }
    50% {
      transform:  translate(-750px,400px)  rotate(0deg) scale(0.2, 0.2);
      opacity: 0.2;
    }
    100% {
      transform:  translate(0,0)  rotate(0deg) scale(0.5, 0.5);
      opacity: 1;
    }
}

.parallax__iaq {
    background-image: url('/img/indoor-air-quality/indoor-air-quality_bg.jpg');

    color: $sabiana-white;

    .content {
        // position: absolute;
        z-index: 10;
        margin: 0 auto;
        width: 98%; // avoiding overflow
        padding: 15px;
        padding-top: 100px;
    }
    .particles {
        position: absolute;
        width: 98%; // avoiding overflow
        height: 100%;
        margin-top: -100px;
    }

    .particle {
        position: absolute;
        z-index: 0;
    }
    .particle-1 {
        width: 140px;
        height: 140px;
        background-image: url('/img/indoor-air-quality/particles/particle-1.png');
        &.a {
            bottom: 40%;
            left: 10%;
            transform: scale(0.3, 0.3);
            opacity: 0.4;
            animation: 12s ease-in-out 1s infinite animate-particle-1-a;
        }
        &.b {
            bottom: 60%;
            right: 30%;
            transform: scale(0.2, 0.2);
            opacity: 0.6;
            animation: 8s ease-in-out 1s infinite animate-particle-1-b;
        }
        &.c {
            bottom: 40%;
            left: 20%;
            transform: scale(0.4, 0.4);
            animation: 10s ease-in-out 1s infinite animate-particle-1-c;
        }
    }

    .particle-2 {
        width: 183px;
        height: 183px;
        background-image: url('/img/indoor-air-quality/particles/particle-2.png');
        &.a {
            bottom: 40%;
            left: 30%;
            transform: scale(0.2, 0.2);
            animation: 14s ease-in-out 1s infinite animate-particle-2-a;
        }
        &.b {
            top: 20%;
            left: 20%;
            transform: scale(0.1, 0.1);
            animation: 9s ease-in-out 1s infinite animate-particle-2-b;
        }
        &.c {
            top: 40%;
            right: 20%;
            transform: scale(0.2, 0.2);
            animation: 14s ease-in-out 1s infinite animate-particle-2-c;
        }
        &.d {
            bottom: 20%;
            right: 30%;
            transform: scale(0.1, 0.1);
            animation: 9s ease-in-out 1s infinite animate-particle-2-d;
        }
    }

    .particle-3 {
        top: 40%;
        left: 30%;
        width: 407px;
        height: 407px;
        transform: scale(0.5, 0.5);
        background-image: url('/img/indoor-air-quality/particles/particle-3.png');
        animation: 12s linear 1s infinite animate-particle-3;
    }

    .particle-4 {
        width: 37px;
        height: 37px;
        background-image: url('/img/indoor-air-quality/particles/particle-4.png');
        &.a {
            bottom: 80%;
            right: 10%;
            animation: 25s ease-in-out 1s infinite animate-particle-4-a;
        }
        &.b {
            top: 40%;
            left: 20%;
            animation: 20s ease-in-out 1s infinite animate-particle-4-b;
        }
        &.c {
            bottom: 60%;
            left: 30%;
            animation: 25s ease-in-out 1s infinite animate-particle-4-c;
        }
    }

    .particle-5 {
        top: 20%;
        left: 10%;
        width: 328px;
        height: 328px;
        transform: scale(0.6, 0.6);
        background-image: url('/img/indoor-air-quality/particles/particle-5.png');
        animation: 13s linear 1s infinite animate-particle-5;
    }

    .particle-6 {
        width: 187px;
        height: 187px;
        background-image: url('/img/indoor-air-quality/particles/particle-6.png');
        &.a {
            top: 20%;
            right: 30%;
            transform: scale(0.4, 0.4);
            animation: 25s linear 1s infinite animate-particle-6-a;
        }
        &.b {
            top: 30%;
            left: 30%;
            transform: scale(0.3, 0.3);
            animation: 20s linear 1s infinite animate-particle-6-b;
        }
    }

    .particle-7 {
        width: 69px;
        height: 69px;
        background-image: url('/img/indoor-air-quality/particles/particle-7.png');
        &.a {
            bottom: 50%;
            right: 30%;
            animation: 25s linear 1s infinite animate-particle-7-a;
        }
        &.b {
            top: 50%;
            left: 30%;
            animation: 15s linear 1s infinite animate-particle-7-b;
        }
        &.c {
            bottom: 20%;
            left: 50%;
            animation: 22s linear 1s infinite animate-particle-7-c;
        }
    }


}
