.news
{
  .img_header {
    width: 100%;
    height: 600px;
    background-position: center;
    /*Added by Ahmed*/
    background-size: cover;
    background-repeat: no-repeat;
    // height: 300px;
  }
  margin-top: 20px;
  article {
    margin: 0 0 80px;
  }
  
  .img_bg {
    width: 42%;
    height: 200px;
    float: left;
    background-size: cover;
    background-position: center;
  }
  h1
  {
    font-size : 48px;
    font-size : 3em;
    text-align: center;
  }
  
  
  p
  {
    font-size : 20px;
    font-size : 1.250em;
    line-height : 30px;
    letter-spacing : 0.1px;
    /*bottom: 25px !important;*/
    strong {
      font-weight: 600;
    }
    a { color: #0063A3 !important; } 
    
  }
  time {
    font-size : 23px;
    font-size: 1.438em;
    letter-spacing : -0.23px;
    color : $sabiana-blue;
  }
  img {
    margin: 0 0 28px;
  }
}

.news.centered {
  text-align: center;
}
 

.academy-archive {
  
  time {
    line-height: 130% !important;
    font-size: 14px !important;
    height: 45px !important;
    padding-top: 5px !important;
    top: 10px !important;
  }
  
  .img_bg {
    width: 100%;
    height: 140px;
    float: left;
    background-size: cover;
    background-position: center;
  }
  
  .img_bg_mini {
    width: 40%;
    height: 140px;
    float: left;
    background-size: cover;
    background-position: center;
    margin-right: 20px;
  }
  h1
  {
    font-size : 48px;
    font-size : 3em;
    text-align: center;
  }
  
  h4 , h5 {
    margin-left: 0px !important;
    color: #58595B !important;
  }
  
  h5 {
    font-size: 15px !important;
  }
  
  p
  {
    font-size : 20px;
    font-size : 1.250em;
    line-height : 30px;
    letter-spacing : 0.1px;
    bottom: 25px !important;
    strong {
      font-weight: 600;
    }
    a { color: #0063A3 !important; } 
    
  }
  time {
    font-size : 23px;
    font-size: 1.438em;
    letter-spacing : -0.23px;
    color : $sabiana-blue;
  }
  img {
    margin: 0 0 28px;
  }
  
  .panel-news {
    min-height: 240px !important;
    padding: 5px !important;
    

    .panel-heading {
        padding: 0 15px !important;
        min-height: 70px !important;
        margin-left: 80px;
    }
  }
}
 