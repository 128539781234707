.top-bar {
    background: $sabiana-white;
    border: none;
    font-size: 13px;
    text-transform: uppercase;
    form.login {
        margin: 30px;
        width: 300px;
    }


    .navbar-login-button {
        width: 44px;
        height: 44px;
        padding: 4px;

        img {
          width: 100%;
        }

        i {
          font-size: 28px; // fa-x not working
        }
    }
}

.top-menu {
    .navbar-header {
        padding: 15px 0;
    }
    .navbar-left {
        padding-left: 15px;
    }
    background: $sabiana-menu-background;
    border: none;
    font-size: 20px;
    //height: 95px;
    button {
        margin-top: 15px;
    }
    &__right {
        background: $sabiana-menu-background;
    }
    .nav {
        float: none;
        border: none;
        // white-space: nowrap;
        padding-top: 0;

        li {
            padding: 5px 0;
            line-height: 1;
            a:first-line {
              margin-left: 0;
            }
            a {
                margin-left: -15px;
                line-height: 1;
                @include regular();
                color: $sabiana-dark-grey;
                &:hover, &:active {
                    background: none;
                    color: $sabiana-blue;
                }
            }
            &.active a {
                background: none;
                color: $sabiana-blue;
            }
            &.active a:hover {
                background: none;
                color: $sabiana-blue;
            }
        }
    }

    /*@media (max-width: 1200px) {
        .navbar-header {
            float: none;
        }
        .navbar-left,.navbar-right {
            float: none !important;
        }
        .navbar-toggle {
            display: block;
        }
        .navbar-collapse {
            border-top: 1px solid transparent;
            box-shadow: inset 0 1px 0 rgba(255,255,255,0.1);
        }
        .navbar-fixed-top {
            top: 0;
            border-width: 0 0 1px;
        }
        .navbar-collapse.collapse {
            display: none!important;
        }
        .navbar-nav {
            float: none!important;
            margin-top: 7.5px;
        }
        .navbar-nav>li {
            float: none;
        }
        .navbar-nav>li>a {
            padding-top: 10px;
            padding-bottom: 10px;
        }
        .collapse.in{
            display:block !important;
        }
    }
    */

}

/* Custom, iPhone Retina */
@media only screen and (min-width : 320px) {

}

/* Extra Small Devices, Phones */
@media only screen and (min-width : 480px) {

}

/* Small Devices, Tablets */
@media only screen and (min-width : 768px) {
    .top-menu {
        .navbar-left {
            padding-left: 0;
        }
    }
}

/* Medium Devices, Desktops */
@media only screen and (min-width : 992px) {
    .top-menu {
        .nav {
            padding-top: 44px;
            li {
                padding: 0 2px;
            }
        }
    }
}

/* Large Devices, Wide Screens */
@media only screen and (min-width : 1200px) {
    .top-menu {
        .nav {
            li {
                padding: 0 16px;
            }
        }
    }
}

/* Extra Large Devices, Wide Screens */
@media only screen and (min-width : 1560px) {
    .top-menu {
        .nav {
            li {
                padding: 0 38px;
            }
        }
    }
}
