#downloads {
    h1 {
        font-family: $font-stack;
        font-size : 60px;
        color : $sabiana-text;
    }

    h3.header {
        background: $sabiana-dark-grey;
        font-family: $font-stack;
        font-size : 48px;
        color : $sabiana-white;
        padding: 10px 0;
    }

    .product_category {
        background: $sabiana-light-grey;
        i {
            margin: 0 10px;
        }
        i.icon-unit-heaters {
            color: $sabiana-unit-heaters;
        }
        i.icon-radiant-panels {
            color: $sabiana-radiant-panels;
        }
        i.icon-air-handling-units {
            color: $sabiana-air-handling-units;
        }
        i.icon-filters {
            color: $sabiana-filters;
        }
        i.icon-fan-coils {
            color: $sabiana-fan-coils;
        }
        i.icon-stainless-steel-flues {
            color: $sabiana-stainless-steel-flues;
        }
        i.icon-recovery-units {
            color: $sabiana-recovery-units;
        }
        i.icon-other-products {
            color: $sabiana-other-products;
        }
        i.icon-fan-convector {
            color: $sabiana-fan-convector;
        }
        font-size: 16px;
        height: 70px;
        font-weight: bold;
        span {
            margin-top: 16px;
        }
        a {
            color: $sabiana-text;
            cursor: pointer;
            display: block;

            div {
              float: left;
            }

            .name {
              margin-top: 10px;
              max-width: 145px;
            }
            .arrow {
              float: right;
            }
        }
        margin-bottom: 4px;
        padding: 5px 15px;
    }
    .download_product {
        height: 35px;
        padding: 0 15px;
        background: $sabiana-light-grey;
        font-size: 16px;
        font-weight: normal;
        border-bottom: 1px solid $sabiana-mid-grey;


        span {
            margin-top: 8px;
        }
        a {
            display: block;
            color: $sabiana-text;
            cursor: pointer;
            span { @include text-wrap(220px); }

        }
    }

    .download_category {
        height: 35px;
        padding: 0 15px;
        background: $sabiana-light-grey;
        font-size: 16px;
        font-weight: normal;
        border-bottom: 1px solid $sabiana-mid-grey;
        span {
            margin-top: 4px;
            @include text-wrap(235px);
        }
        a {
            display: block;
            color: $sabiana-text;
            cursor: pointer;
            margin-left: 10px;

        }
    }
    .download_files {
        padding: 0 15px;
        background: #ddd;
        font-size: 16px;
        font-weight: normal;
        border-bottom: 1px solid $sabiana-dark-grey;
        span {
            margin-top: 8px;
            @include text-wrap(230px);
        }
        a {
            display: block;
            color: $sabiana-text;
            cursor: pointer;
        }

    }
}

.other_downloads {
    div {
        font-size: 16px;
        font-weight: 700;
        border-bottom: 1px solid $sabiana-mid-grey;
        padding: 15px 10px;
        a { @include text-wrap(310px); }
        span { @include text-wrap(260px); cursor: pointer; }
        i {
            margin-top: -10px;
        }
    }
}

.loader {
    border: 12px solid #f3f3f3; /* Light grey */
    border-top: 12px solid #3498db; /* Blue */
    border-radius: 50%;
    width: 6px;
    height: 6px;
    animation: spin 2s linear infinite;
    //position: absolute;
    margin-left: -40px;
    margin-top: -36px;
}

@keyframes spin {
    0% { transform: rotate(0deg); }
    100% { transform: rotate(360deg); }
}

@media only screen and (min-width : $screen-xs-min) {
    #downloads {
      .product_category {
        a {
            .name {
              margin-top: 10px;
              max-width: 300px;
            }
          }
      }
    }
}
/*


    .product-header {

    }

    .panel {
        border: 0;
        background: transparent;
        box-shadow: 0;
    }

    .panel-heading {
        background: $sabiana-white;
        border-bottom: 1px solid $sabiana-mid-grey;
        h4 {
            font-size : 23px;
            a:after {
                font-family: 'Glyphicons Halflings';
                content: "\e114";
                float: right;
                color: grey;
            }
            a.collapsed:after {
                content: "\e113";
            }
            a:focus {
                text-decoration: none;
            }
        }
    }

    .panel-body {
        background: $sabiana-light-grey;
        padding: 0;
        div {
            font-size: 18px;
            font-weight: 700;
            border-bottom: 1px solid $sabiana-mid-grey;
            padding: 15px 10px;
            i {
                margin-top: -10px;
            }
        }
    }

    .downloadrow {
        margin-bottom: 100px;
    }

    .other_downloads {
        div {
            font-size: 18px;
            font-weight: 700;
            border-bottom: 1px solid $sabiana-mid-grey;
            padding: 15px 10px;
            i {
                margin-top: -10px;
            }
        }
    }
}
*/
