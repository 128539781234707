.wf-loading h1,
.wf-loading h2,
.wf-loading h3,
.wf-loading h4,
.wf-loading h5,
.wf-loading h6,
.wf-loading p,
.wf-loading li,
.wf-loading label,
.wf-loading a,
.wf-loading button  {
    visibility: hidden;
}
.wf-inactive h1,
.wf-inactive h2,
.wf-inactive h3,
.wf-inactive h4,
.wf-inactive h5,
.wf-inactive h6,
.wf-inactive p,
.wf-inactive li,
.wf-inactive label,
.wf-inactive a,
.wf-inactive button
.wf-active h1,
.wf-active h2,
.wf-active h3,
.wf-active h4,
.wf-active h5,
.wf-active h6,
.wf-active p,
.wf-active li,
.wf-active label,
.wf-active a,
.wf-active button  {
    visibility: visible;
}

$font-stack: "myriad-pro-semi-condensed",sans-serif;

@mixin light() {
  font-family: $font-stack;
  font-style: normal;
  font-weight: 300;
}

@mixin regular() {
  font-family: $font-stack;
  font-style: normal;
  font-weight: 400;
}

@mixin semibold() {
  font-family: $font-stack;
  font-style: normal;
  font-weight: 600;
}

@mixin bold() {
  font-family: $font-stack;
  font-style: normal;
  font-weight: 700;
}

/*
.wf-loading:after {

    // first up, we need to define some content
    content: "Loading fonts...";

    // let's now give it some dimensions, a background color and position it on the page
    background: white;
    width: 100%;
    height: 100%;
    position: fixed;
    top: 0;
    left: 0;

    // okay, so what do we want our label to actually look like?
    color: $sabiana-blue;
    font-size: 2em;
    font-weight: bold;
    line-height: 20em;
    text-align: center;

}

.wf-loading * {

    // first things first, we need to hide everything, but bear in mind that this will only take effect once the loading script has taken effect. here we're hiding all content within the <html> once it has the class "wf-loading"
    opacity: 0;
}

.wf-loading {

    // here's a background image (at a meer 723bytes) to indicate something's happening
    background: url('/img/loader.gif') no-repeat center center;

    // just to make sure the <html>   element shares the same dimensions as the browser window when loading and not the potentially elongated page)
    height: 100%;
    overflow: hidden;

}
*/
