#history {
    div.hero {
        position: relative;
        display: inline-block;
        width: 100%;
    }

    img {
        width: 100%;
        max-width: 100%;
    }

    .caption {
        position: absolute;
        width: 100%;
        bottom: 20%;
        left: 0;
        font-size: 60px;
        color: $sabiana-white;
        text-align: center;
    }

    .content {
        padding: 50px 0;
    }

    p {
        font-size: 18px;
        line-height: 24px;
        color: $sabiana-text;

        .intro {
            font-weight: 900;
            font-size: 18px;
            line-height: 24px;
            color: $sabiana-text;
        }
    }

    h1 {
        font-size: 60px;
        color: $sabiana-blue;
    }

    section.timeline {
        background: $sabiana-light-grey;
        padding: 10px 0 50px;

        h2 {
            padding: 0 0 50px;
            font-size: 48px;
            font-weight: 400;
            letter-spacing: 1.2px;
            color: $sabiana-dark-grey;
        }

        .item {
            date {
                font-size: 23px;
                font-weight: 900;
            }

            h3 {
                font-size: 23px;
                font-weight: 400;
                white-space: nowrap;
            }

            p {
                font-size: 16px;
                font-weight: 400;
                min-height: 180px;
                height: 140px;
            }

            img {
                min-width: 50%;
                max-width: 50%;
            }
        }

        .carousel-control {
            position: absolute;
            top: auto;
            bottom: -50px;
            background: none;
            width: 90%;
            color: $sabiana-dark-grey;
        }

        .carousel-indicators {
            display: none;
        }
    }
}
