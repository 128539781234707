footer {
    .nav-justified > li,
    .nav-tabs.nav-justified > li {
        white-space: nowrap;
        font-size: 14px;
    }
    position: relative;
    z-index: 900;

    .contacts {
        padding-top: 40px;
        line-height: 1em;
        // p {
        //     white-space: nowrap; // nowrap is making the text pass to another column in medium size
        // }
    }
    .sales {
        padding-top: 10px;
    }
    .socials {
        padding-top: 40px;
    }

    [class*=" icon-"]:before,
    [class^="icon-"]:before {
        margin-right: 0.1em;
    }
    background: $sabiana-blue;
    color: $sabiana-dark-grey;
    color: $sabiana-white;

    .footer-menu {
        background: #fff;

        a {
            color: $sabiana-dark-grey;
            text-align: left;
            margin-left: 20px;
            &:hover {
                color: $sabiana-blue;
            }
        }
    }

    a {
        color: #fff;

        &:focus,
        &:hover {
            color: #ddd;
        }
    }

    .nav li a {
        &:hover {
            background: none;
        }
    }

    h3 {
        @include light();
        font-size: 23px;
        font-size: 1.438em;
        letter-spacing: 0.58px;
        text-align: left;
    }

    h4 {
      margin-bottom: 2px;
    }

    ul li {
        font-size: 18px;
        font-size: 1.125em;
        line-height: 15px;
    }

    .footer-menu img.logo {
        padding: 10px;
        float: right;
    }

    .select-box {
        cursor: pointer;
        position: relative;
        max-width: 20em;
        width: 100%;
    }

    .label,
    .select {
        color: #414141;
        display: block;
        text-align: left;
        font: 400 17px/2em 'Source Sans Pro', sans-serif;
    }

    .select {
        width: 100%;
        position: absolute;
        top: 0;
        padding: 5px 0;
        height: 40px;
        opacity: 0;
        -ms-filter:  "progid:DXImageTransform.Microsoft.Alpha(Opacity=0)";
        background: none transparent;
        border: 0 none;
    }

    .select-box1 {
        background: $sabiana-white;
    }

    .label {
        position: relative;
        padding: 5px 10px;
        cursor: pointer;
    }

    .open .label::after {
        content: "▲";
    }

    .label::after {
        content: "▼";
        font-size: 12px;
        position: absolute;
        right: 0;
        top: 0;
        padding: 5px 15px;
        border-left: 5px solid #fff;
    }

    .icon-android:before,
    .icon-email:before,
    .icon-facebook:before {
        margin-left: 0;
    }
    
    .fa-pinterest {
        border: 1px solid white;
        border-radius: 45px;
        padding: 8px;
        height: 45px;
        width: 45px;
        margin-left: 15px;
        font-size: 1.7rem;
        text-align: center;
        position: relative;
        top: -3px;
    }
}
@media (max-width: 768px) {
    footer .footer-menu img.logo {
        float: none;
        display: block;
        margin-left: auto;
        margin-right: auto;
    }
}

i.fa.fa-youtube-play.fa-1x {
    display: inline-block;
    border-radius: 60px;
    border: 1px solid white;
    padding: 10px 10px;
    font-size: 1.5em;
    position: relative;
    top: -5px;
    margin-left: 10px;
}