@font-face {
  font-family: 'sabiana';
  src: url('../fonts/sabiana.eot?97117801');
  src: url('../fonts/sabiana.eot?97117801#iefix') format('embedded-opentype'),
       url('../fonts/sabiana.woff2?97117801') format('woff2'),
       url('../fonts/sabiana.woff?97117801') format('woff'),
       url('../fonts/sabiana.ttf?97117801') format('truetype'),
       url('../fonts/sabiana.svg?97117801#sabiana') format('svg');
  font-weight: normal;
  font-style: normal;
}
@font-face {
  font-family: 'fontello';
  src: url('../fonts/fontello.eot?94271041');
  src: url('../fonts/fontello.eot?94271041#iefix') format('embedded-opentype'),
       url('../fonts/fontello.woff2?94271041') format('woff2'),
       url('../fonts/fontello.woff?94271041') format('woff'),
       url('../fonts/fontello.ttf?94271041') format('truetype'),
       url('../fonts/fontello.svg?94271041#fontello') format('svg');
  font-weight: normal;
  font-style: normal;
}
/* Chrome hack: SVG is rendered more smooth in Windozze. 100% magic, uncomment if you need it. */
/* Note, that will break hinting! In other OS-es font will be not as sharp as it could be */
/*
@media screen and (-webkit-min-device-pixel-ratio:0) {
  @font-face {
    font-family: 'sabiana';
    src: url('../fonts/sabiana.svg?97117801#sabiana') format('svg');
  }
}
*/

 [class^="icon-"]:before, [class*=" icon-"]:before {
  font-family: "sabiana";
  font-style: normal;
  font-weight: normal;
  speak: none;

  display: inline-block;
  text-decoration: inherit;
  width: 1em;
  margin-right: .2em;
  text-align: center;
  /* opacity: .8; */

  /* For safety - reset parent styles, that can break glyph codes*/
  font-variant: normal;
  text-transform: none;

  /* fix buttons height, for twitter bootstrap */
  line-height: 1em;

  /* Animation center compensation - margins should be symmetric */
  /* remove if not needed */
  margin-left: .2em;

  /* you can be more comfortable with increased icons size */
  /* font-size: 120%; */

  /* Font smoothing. That was taken from TWBS */
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;

  /* Uncomment for 3D effect */
  /* text-shadow: 1px 1px 1px rgba(127, 127, 127, 0.3); */
}

.icon-air-handling-units:before { content: '\e800'; } /* '' */
.icon-android:before { content: '\e801'; } /* '' */
.icon-appstore:before { content: '\e802'; } /* '' */
.icon-arrow-left:before { content: '\e803'; } /* '' */
.icon-arrow-right:before { content: '\e804'; } /* '' */
.icon-email:before { content: '\e805'; } /* '' */
.icon-facebook:before { content: '\e806'; } /* '' */
.icon-pinterest:before { content: '\f231'; } /* '' */
.icon-recovery-units:before { content: '\e807'; } /* '' */
.icon-filters:before { content: '\e808'; } /* '' */
.icon-linkedin:before { content: '\e809'; } /* '' */
.icon-instagram:before { content: '\e80a'; } /* '' */
.icon-lock:before { content: '\e80b'; } /* '' */
.icon-login:before { content: '\e80c'; } /* '' */
.icon-other-products:before { content: '\e80d'; } /* '' */
.icon-radiant-panels:before { content: '\e80e'; } /* '' */
.icon-read-more:before { content: '\e80f'; } /* '' */
.icon-fan-coils:before { content: '\e810'; } /* '' */
.icon-search:before { content: '\e811'; } /* '' */
.icon-stainless-steel-flues:before { content: '\e812'; } /* '' */
.icon-twitter:before { content: '\e813'; } /* '' */
.icon-unit-heaters:before { content: '\e814'; } /* '' */
.icon-check:before { content: '\e815'; } /* '' */
.icon-appstore-de:before { content: '\e816'; } /* '' */
.icon-download:before { content: '\e817'; } /* '' */
.icon-hamburger:before { content: '\e818'; } /* '' */
.icon-plus:before { content: '\e819'; } /* '' */
.icon-appstore-en:before { content: '\e81a'; } /* '' */
.icon-square-empty:before { content: '\e81b'; } /* '' */
.icon-square-fill:before { content: '\e81c'; } /* '' */
.icon-square-plus:before { content: '\e81d'; } /* '' */
.icon-triangle-down:before { content: '\e81e'; } /* '' */
.icon-triangle-left:before { content: '\e81f'; } /* '' */
.icon-triangle-right:before { content: '\e820'; } /* '' */
.icon-triangle-up:before { content: '\e821'; } /* '' */
.icon-cross:before { content: '\e822'; } /* '' */
.icon-square-cross:before { content: '\e823'; } /* '' */
.icon-arrow-down:before { content: '\e824'; } /* '' */
.icon-arrow-up:before { content: '\e825'; } /* '' */
.icon-appstore-fr:before { content: '\e826'; } /* '' */
.icon-appstore-it:before { content: '\e827'; } /* '' */
.icon-appstore-ru:before { content: '\e828'; } /* '' */
.icon-fan-convector:before { font-family: 'fontello'; content: '\e800';    margin-left: -3px; margin-right: 13px; } /* '' */

.icon-1-2x{font-size: 1.2em;}
.icon-1-5x{font-size: 1.5em;}
.icon-2x{font-size: 2em;}
.icon-3x{font-size: 3em;}
.icon-4x{font-size: 4em;}
.icon-5x{font-size: 5em;}
