.press
{
  
  article {
    margin: 0 0 10px 10px;
    background: #FFFFFF;
    height: 150px;
  }
  h1
  {
    font-size : 48px;
    font-size : 3em;
  }
  h2
  {
    font-size : 25px;
    font-size : 1.5em;
  }
  p
  {
    font-size : 20px;
    font-size : 1.5em;
    line-height : 30px;
    letter-spacing : 0.1px;
    strong {
      font-weight: 600;
    }
  }
  time {
    font-size : 23px;
    font-size: 1.438em;
    letter-spacing : -0.23px;
    color : $sabiana-blue;
  }
  img {
    margin: 0 0 28px;
  }
}

.see_archive {
  margin: 30px 0;
  a {
    font-size: 25px;
  }
}

.press.centered {
  text-align: center;
}

.two-col {
  width: 100%;
}
@media only screen and (min-width : $screen-md-min) { 
  .two-col {
  width: 45%;
}
}

@media only screen and (min-width : $screen-lg-min) {
  .two-col {
    width: 49%;
    
  }
}

