@import "particles";


.indoor-air-quality {
    padding: 0;
    .dark {
        padding-bottom: 20px;
        color: $sabiana-white;
        background: $sabiana-dark-grey;
        h2 {
            font-size: 50px;
        }
        a {
            font-size: 30px;
            color: $sabiana-white;
            &:hover {
                color: $sabiana-blue;
            }
        }
    }
    [class*="parallax__"] {
        min-height: 85vh;
        // background-attachment: fixed;
        background-position: center center;
        background-size: cover;
        position: relative;

        h1, h2, p {
          position: relative;
          z-index: 100;
        }
    }

    [class*="aside__"] {
        padding: 60px 50px 0;
        img {
            margin-top: 32px;
            width: 100%;
        }

        h3 {
            color: $sabiana-blue;
        }

        h4 {
            @include light()
        }
    }
    div.read-position {
      margin-left: 50%;
      left: -40px;
      position: relative;
      margin-bottom: 180px;
      margin-top: 15px;
    }
    a.read-more {
        // position: absolute;
        position: relative;
        // bottom: 10px;
        // left: 50%;
        color: $sabiana-white;
        display: table-cell;
        width: 80px;
        height: 80px;
        text-align: center;
        margin-left: -40px;
        padding-top: 50px;
        background: url('/img/cross.png') 50% top no-repeat;
        cursor: pointer;
        z-index: 100;

        &.collapsed {
            background: url('/img/plus.png') 50% top no-repeat;
        }
    }
}

/* Custom, iPhone Retina < XS */
@media only screen and (min-width : 320px) {

    .parallax__iaq .content {
      padding-top: 10%;
    }
    .indoor-air-quality h1 {
        margin: 0;
        padding: 0;
        line-height: 1.5;
        font-size: 30px;
    }
    .indoor-air-quality h2 {
        margin: 0;
        padding: 0;
        line-height: 1.5;
        font-size: 18px;
        text-transform: uppercase;
        @include bold();
    }
    .indoor-air-quality h3 {
        margin: 0;
        padding: 0;
        line-height: 1.5;
        font-size: 40px;
    }
    .indoor-air-quality h4 {
        margin: 0;
        padding: 0;
        line-height: 1;
        font-size: 36px;
        @include light();
    }
    .indoor-air-quality p {
        margin: 0;
        padding: 0;
        line-height: 1.4;
        font-size: 18px;
    }

}

/* Extra Small Devices, Phones XS */
@media only screen and (min-width : $screen-xs-min) {
    .indoor-air-quality h1 {
        font-size: 30px;
        line-height: 100%;
    }
    .indoor-air-quality h2 {
        font-size: 18px;
        line-height: 100%;
        padding: 10px;
    }
    .indoor-air-quality h3 {
        font-size: 40px;
    }
    .indoor-air-quality h4 {
        font-size: 36px;
    }
    .indoor-air-quality p {
        font-size: 18px;
    }
    .indoor-air-quality {
        div.read-position {
           margin-bottom: 125px;
       }
    }
}

/* Small Devices, Tablets SM */
@media only screen and (min-width : $screen-sm-min) {

    .indoor-air-quality h1 {
        font-size: 50px;
        line-height: 1.2;
    }
    .indoor-air-quality h2 {
        font-size: 30px;
        padding: 20px;
    }
    .indoor-air-quality h3 {
        font-size: 40px;
    }
    .indoor-air-quality h4 {
        font-size: 36px;
    }
    .indoor-air-quality p {
        font-size: 22px;
    }

    .indoor-air-quality {
        div.read-position {
           margin-bottom: 175px;
       }
    }
}

/* Medium Devices, Desktops MD */
@media only screen and (min-width : $screen-md-min) {
    .parallax__iaq {
        .content {
            padding-top: 140px 20px 0;
        }
    }
    .indoor-air-quality h1 {
        font-size: 60px;
    }
    .indoor-air-quality h2 {
        font-size: 40px;
    }
    .indoor-air-quality h3 {
        font-size: 40px;
    }
    .indoor-air-quality h4 {
        font-size: 32px;
    }
    .indoor-air-quality p {
        font-size: 24px;
    }

    .indoor-air-quality {
        div.read-position {
           margin-bottom: 220px;
       }
    }
}

/* Large Devices, Wide Screens LG */
@media only screen and (min-width : $screen-lg-min) {
    .indoor-air-quality h1 {
        font-size: 60px;
    }
    .indoor-air-quality h2 {
        font-size: 40px;
    }
    .indoor-air-quality h3 {
        font-size: 40px;
    }
    .indoor-air-quality h4 {
        font-size: 32px;
    }
    .indoor-air-quality p {
        font-size: 24px;
    }
    .indoor-air-quality {
        div.read-position {
           margin-bottom: 210px;
       }
    }
}

/* Large Devices, Wide Screens XL */
@media only screen and (min-width : $screen-xl-min) {

}
