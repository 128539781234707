// Grid
$grid-columns: 16;
$grid-gutter-width: 30px;
$container-large-desktop: (1410px + $grid-gutter-width);

// Colors
$sabiana-unit-heaters: #f98787;
$sabiana-radiant-panels: #f4ac90;
$sabiana-air-handling-units: #629ff9;
$sabiana-filters: #80ca96;
$sabiana-fan-coils: #80d6ed;
$sabiana-stainless-steel-flues: #aaaaaa;
$sabiana-recovery-units: #c5d984;
$sabiana-other-products: #f9ce64;
$sabiana-blue: #0063a3; // rgb(0, 99, 163);
$sabiana-light-grey: #f7f7f7;
$sabiana-stroke: #cccccc;
$sabiana-text: #58595b; // rgb(88, 89, 91);
$sabiana-dark-grey: #404041; // rgb(64, 64, 65);
$sabiana-mid-grey: #808080; // rgb(128, 128, 128);
$sabiana-white: #ffffff;
$sabiana-light-blue: #f5f8fa;
$sabiana-menu-background: #f3f3f3;
$sabiana-fan-convector: #85CEE4;

// Body
$body-bg: #f5f8fa;

// Typography
//$font-family-sans-serif: "Raleway", sans-serif;
$font-size-base: 14px;
$line-height-base: 1.6;
$text-color: #636b6f;

// Forms
$border-radius-base:0;

// Gallery
$gallery-photo-height: 160px;
