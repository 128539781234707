    // Caption
    .carousel-caption {
      //position: absolute;
      //top: 40%;
      //padding: 0px 200px;
      h3 {
        //font-size : 60px;
        //font-size: 3.750em;
        //color : #E4E4E5;
        //color : rgb(228, 228, 229);
      }
      p {
        //font-size : 28px;
        //font-size: 1.750em;
        //letter-spacing : 0.7px;
        //color : $sabiana-light-grey;
        //text-transform: uppercase;
      }
    }

    // Image
    .carousel img {
        //min-width: 100%;
    }

    // Arrows
    .carousel-control {
      .icon-arrow-left,
      .icon-arrow-right {
        //position: absolute;
        //top: 50%;
        //margin-top: -10px;
        //z-index: 5;
        //display: inline-block;
      }
      .icon-arrow-left {
        //left: 50%;
        //margin-left: -10px;
      }
      .icon-arrow-right {
        //right: 50%;
        //margin-right: -10px;
      }
    }

/* Extra Small Devices, Phones */
@media only screen and (min-width : $screen-xs-min) {
    .carousel-caption {
      //top: -10%;
      h3 {
        //font-size : 34px;
        //font-size: 2.125em;
      }
      p{
        //font-size : 16px;
        //font-size: 1em;
      }
    }
}
/* Small Devices, Tablets */
@media only screen and (min-width : $screen-sm-min) {
    .carousel-control {
      .icon-arrow-left,
      .icon-arrow-right,
      .icon-arrow-left {
        //margin-left: ($carousel-control-font-size / -2);
      }
      .icon-arrow-right {
        //margin-right: ($carousel-control-font-size / -2);
      }
    }
}

/* Medium Devices, Desktops */
@media only screen and (min-width : $screen-md-min) {
    .carousel-caption {

      //top: 10%;
      h3 {
        //font-size : 34px;
        //font-size: 2.500em;
      }
      p{
        //font-size : 18px;
        //font-size: 1.750em;
      }
        
    }
}

/* Large Devices, Wide Screens */
@media only screen and (min-width : $screen-lg-min) {

}

/* Extra Large Devices, Ultra Wide Screens */
@media only screen and (min-width : $screen-xl-min) {

}
