section {
  padding-bottom: 50px;
  &.dark{
    background: $sabiana-dark-grey;
    color : $sabiana-white;
  }
  h1 {
    text-align: center;
    font-style: normal;
    font-weight: 300;
    margin: 20px 0 40px;
  }
  h2 {
    text-align: center;
    font-size : 48px;
    font-size : 3em;
    font-style: normal;
    font-weight: 300;
    padding: 20px 0;
    margin: 0 0 40px;
  }
  h3 {
    font-size : 23px;
    font-size : 1.438em;
  }
  h4 {
    font-size : 18px;
    font-size : 1.125em;
  }
  p {
    font-size : 16px;
    font-size : 1em;
    letter-spacing : 0.08px;
  }
}

.space-border {
      padding: 50px;
      max-width: 1200px;
      margin: auto;
      
    }
    
    .space-border h1, h2 {
      text-align: center;
    }
    
    .space-border p, ol {
      text-align: justify;
      font-size: 1.2rem;
    }